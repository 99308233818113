import {
  ANODE,
  CATHODE,
  COBALT,
  BATTERY_EMISSIONS_ANALYSER,
  FORECASTS,
  GIGAFACTORY_ASSESSMENT,
  PRECURSOR_ASSESSMENT,
  LITHIUM,
  SILICON_ANODE,
  LITHIUM_ION_BATTERIES,
  MARKET_ASSESSMENTS,
  NATURAL_GRAPHITE,
  NICKEL,
  PRICE_ASSESSMENTS,
  MARKET,
  RECYCLING,
  SOLID_STATE,
  SYNTHETIC_GRAPHITE,
  ESG,
  RARE_EARTHS,
  BLACK_MASS,
  MANGANESE,
  PHOSPHATE,
  GRAPHITE,
  FLUORSPAR,
  SODIUM_ION_BATTERIES,
  PRICE_DASHBOARD,
} from '~/constants/services/constants'
import {
  PRICE_ASSESSMENTS_ANODE,
  PRICE_ASSESSMENTS_CATHODE,
  PRICE_ASSESSMENTS_COBALT,
  PRICE_ASSESSMENTS_LITHIUM,
  PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES,
  PRICE_ASSESSMENTS_NATURAL_GRAPHITE,
  PRICE_ASSESSMENTS_NICKEL,
  PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE,
  PRICE_ASSESSMENTS_BLACK_MASS,
  PRICE_ASSESSMENTS_RARE_EARTHS,
} from '~/constants/services/price-assessments'
import {
  MARKET_ASSESSMENTS_GIGAFACTORY_ASSESSMENT,
  MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT,
  MARKET_ASSESSMENTS_SODIUM_ION_BATTERIES,
} from '~/constants/services/market-assessments'
import {
  FORECASTS_ANODE,
  FORECASTS_LITHIUM_ION_BATTERIES,
  FORECASTS_CATHODE,
  FORECASTS_COBALT,
  FORECASTS_LITHIUM,
  FORECASTS_SILICON_ANODE,
  FORECASTS_NATURAL_GRAPHITE,
  FORECASTS_NICKEL,
  FORECASTS_RECYCLING,
  FORECASTS_SOLID_STATE,
  FORECASTS_SYNTHETIC_GRAPHITE,
  FORECASTS_RARE_EARTHS,
  FORECASTS_MANGANESE,
  FORECASTS_PHOSPHATE,
  FORECASTS_FLUORSPAR,
} from '~/constants/services/forecasts'

import {
  ESG_LITHIUM,
  ESG_COBALT,
  ESG_NICKEL,
  ESG_GRAPHITE,
  ESG_RARE_EARTHS,
  ESG_BATTERY_EMISSIONS_ANALYSER,
} from '~/constants/services/esg'

import { MARKET_PRICE_DASHBOARD } from '~/constants/services/market'

export const servicesAvailable = {
  [`${PRICE_ASSESSMENTS.slug}-${LITHIUM.slug}`]: PRICE_ASSESSMENTS_LITHIUM,
  [`${PRICE_ASSESSMENTS.slug}-${COBALT.slug}`]: PRICE_ASSESSMENTS_COBALT,
  [`${PRICE_ASSESSMENTS.slug}-${NICKEL.slug}`]: PRICE_ASSESSMENTS_NICKEL,
  [`${PRICE_ASSESSMENTS.slug}-${NATURAL_GRAPHITE.slug}`]:
    PRICE_ASSESSMENTS_NATURAL_GRAPHITE,
  [`${PRICE_ASSESSMENTS.slug}-${SYNTHETIC_GRAPHITE.slug}`]:
    PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE,
  [`${PRICE_ASSESSMENTS.slug}-${LITHIUM_ION_BATTERIES.slug}`]:
    PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES,
  [`${PRICE_ASSESSMENTS.slug}-${ANODE.slug}`]: PRICE_ASSESSMENTS_ANODE,
  [`${PRICE_ASSESSMENTS.slug}-${CATHODE.slug}`]: PRICE_ASSESSMENTS_CATHODE,
  [`${MARKET_ASSESSMENTS.slug}-${GIGAFACTORY_ASSESSMENT.slug}`]:
    MARKET_ASSESSMENTS_GIGAFACTORY_ASSESSMENT,
  [`${MARKET_ASSESSMENTS.slug}-${PRECURSOR_ASSESSMENT.slug}`]:
    MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT,
  [`${MARKET_ASSESSMENTS.slug}-${SODIUM_ION_BATTERIES.slug}`]:
    MARKET_ASSESSMENTS_SODIUM_ION_BATTERIES,
  [`${FORECASTS.slug}-${LITHIUM_ION_BATTERIES.slug}`]:
    FORECASTS_LITHIUM_ION_BATTERIES,
  [`${FORECASTS.slug}-${LITHIUM.slug}`]: FORECASTS_LITHIUM,
  [`${FORECASTS.slug}-${COBALT.slug}`]: FORECASTS_COBALT,
  [`${FORECASTS.slug}-${NICKEL.slug}`]: FORECASTS_NICKEL,
  [`${FORECASTS.slug}-${NATURAL_GRAPHITE.slug}`]: FORECASTS_NATURAL_GRAPHITE,
  [`${FORECASTS.slug}-${SYNTHETIC_GRAPHITE.slug}`]:
    FORECASTS_SYNTHETIC_GRAPHITE,
  [`${FORECASTS.slug}-${SOLID_STATE.slug}`]: FORECASTS_SOLID_STATE,
  [`${FORECASTS.slug}-${ANODE.slug}`]: FORECASTS_ANODE,
  [`${FORECASTS.slug}-${CATHODE.slug}`]: FORECASTS_CATHODE,
  [`${FORECASTS.slug}-${SILICON_ANODE.slug}`]: FORECASTS_SILICON_ANODE,
  [`${FORECASTS.slug}-${RECYCLING.slug}`]: FORECASTS_RECYCLING,
  [`${FORECASTS.slug}-${RARE_EARTHS.slug}`]: FORECASTS_RARE_EARTHS,
  [`${FORECASTS.slug}-${MANGANESE.slug}`]: FORECASTS_MANGANESE,
  [`${FORECASTS.slug}-${PHOSPHATE.slug}`]: FORECASTS_PHOSPHATE,
  [`${ESG.slug}-${LITHIUM.slug}`]: ESG_LITHIUM,
  [`${ESG.slug}-${COBALT.slug}`]: ESG_COBALT,
  [`${ESG.slug}-${BATTERY_EMISSIONS_ANALYSER.slug}`]:
    ESG_BATTERY_EMISSIONS_ANALYSER,
  [`${ESG.slug}-${NICKEL.slug}`]: ESG_NICKEL,
  [`${ESG.slug}-${GRAPHITE.slug}`]: ESG_GRAPHITE,
  [`${ESG.slug}-${RARE_EARTHS.slug}`]: ESG_RARE_EARTHS,
  [`${PRICE_ASSESSMENTS.slug}-${BLACK_MASS.slug}`]:
    PRICE_ASSESSMENTS_BLACK_MASS,
  [`${PRICE_ASSESSMENTS.slug}-${RARE_EARTHS.slug}`]:
    PRICE_ASSESSMENTS_RARE_EARTHS,
  [`${FORECASTS.slug}-${FLUORSPAR.slug}`]: FORECASTS_FLUORSPAR,
  [`${MARKET.slug}-${PRICE_DASHBOARD.slug}`]: MARKET_PRICE_DASHBOARD,
}

export const getServiceByName = (nameService) => {
  return servicesAvailable[nameService] ?? null
}

export const getServiceBySubscription = (subscription) => {
  return Object.values(servicesAvailable).find((_item) => {
    return subscription === _item.subscription
  })
}

export const getServiceByProperty = (value, property) => {
  return Object.values(servicesAvailable).find((_item) => {
    return value === _item[property]
  })
}
