import { servicesAvailable } from '~/constants/services/objects'

export const getServiceDataFromRoute = (route, returnRoute) => {
  const [, market] = route.split('/')
  const item = Object.values(servicesAvailable).find((_item) => {
    return _item?.routes?.default?.includes(market)
  })
  if (item) {
    return {
      subscription: item?.subscription,
      route: item?.routes[returnRoute],
    }
  }
  return { subscription: null, route: null }
}

export const getServicesByRoute = (route) => {
  const [, market, , typeMarket] = route.split('/')

  const marketMapping = {
    'sustainability-natural-graphite': 'graphite',
    'sustainability-synthetic-graphite': 'graphite',
    'market-assessments-cathode': 'assessment-precursor',
    'market-assessments-lithium-ion-batteries': 'assessment-gigafactory',
    'price-assessments-lithium-ion-batteries': 'battery-cell',
    'forecast-lithium-ion-batteries': 'battery-database',
    'market-assessments-sodium-ion-batteries': 'assessment-sodium-ion-battery',
    'price-assessments-recycling': 'black-mass',
  }

  const mappedMarket = marketMapping[`${typeMarket}-${market}`] || market

  return Object.values(servicesAvailable)
    .filter((_item) => {
      const subscription = _item?.subscription?.split('-').slice(1).join('-')
      return subscription === mappedMarket
    })
    .map((_item) => ({
      subscription: _item?.subscription,
      route: _item?.routes ?? null,
    }))
}

export const hasPricingMenu = (memberships) => {
  return Object.keys(memberships)
    .filter((key) => key.startsWith('pricing'))
    .some((key) => memberships[key] === true)
}
